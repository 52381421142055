import { SvgIcon } from "@material-ui/core";
import { useTheme } from "@material-ui/core";

export const Hashtag = () => {
  const theme = useTheme();
  return (
    <div
      style={{
        verticalAlign: "text-bottom",
        fontWeight: "bold",
        marginRight: 3,
        color: theme.palette.text.primary,
      }}
    >
      #
    </div>
  );
};

export const Unique = ({ color, style }) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <mask
        id="mask0"
        maskUnits="userSpaceOnUse"
        x="3"
        y="3"
        width="18"
        height="18"
      >
        <rect x="3" y="3" width="18" height="18" />
      </mask>
      <g mask="url(#mask0)">
        <path
          d="
            M6.87495 11.1285
            C5.93597 11.1285 5.18265 10.3716 5.18265 9.42923
            C5.18265 8.48686 5.93597 7.72996 6.87495 7.72996
            C7.81562 7.72996 8.57421 8.48856 8.57421 9.42923
            C8.57421 10.3699 7.81562 11.1285 6.87495 11.1285
            Z
            M6.57047 13.3105
            C6.09705 13.8574 5.78853 14.5322 5.78853 15.3488
            V16.6999
            H2.39
            V15.3488
            C2.39 15.0865 2.51835 14.8279 2.79792 14.5701
            C3.08016 14.3098 3.4897 14.0774 3.97387 13.8837
            C4.82149 13.5445 5.82255 13.3531 6.57047 13.3105
            Z
            M18.3171 9.42923
            C18.3171 10.3716 17.5638 11.1285 16.6248 11.1285
            C15.6842 11.1285 14.9256 10.3699 14.9256 9.42923
            C14.9256 8.48856 15.6842 7.72996 16.6248 7.72996
            C17.5638 7.72996 18.3171 8.48686 18.3171 9.42923
            Z
            M17.7108 15.3488
            C17.7108 14.5322 17.4022 13.8574 16.9288 13.3105
            C17.6768 13.3531 18.6778 13.5445 19.5254 13.8837
            C20.0096 14.0774 20.4191 14.3098 20.7014 14.5701
            C20.981 14.8279 21.1093 15.0865 21.1093 15.3488
            V16.6999
            H17.7108
            V15.3488
            Z"
          strokeWidth="0.779994"
        />
        <path
          d="
            M13.4422 9.42923
            C13.4422 10.3716 12.6889 11.1285 11.7499 11.1285
            C10.8093 11.1285 10.0507 10.3699 10.0507 9.42923
            C10.0507 8.48856 10.8093 7.72996 11.7499 7.72996
            C12.6889 7.72996 13.4422 8.48686 13.4422 9.42923
            Z
            M7.265 15.3488
            C7.265 15.0865 7.39335 14.8279 7.67292 14.5701
            C7.95516 14.3098 8.3647 14.0774 8.84887 13.8837
            C9.81784 13.4959 10.9873 13.3013 11.7499 13.3013
            C12.5126 13.3013 13.6821 13.4959 14.651 13.8837
            C15.1352 14.0774 15.5447 14.3098 15.827 14.5701
            C16.1065 14.8279 16.2349 15.0865 16.2349 15.3488
            V16.6999
            H7.265
            V15.3488
            Z"
          fill={color}
        />
      </g>
    </SvgIcon>
  );
};

export const NoNull = ({ color, style }) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="
        M15.4583 12.9976
        C15.4583 14.394 15.2198 15.4317 14.7429 16.1106
        C14.266 16.7895 13.5205 17.1289 12.5064 17.1289
        C11.5053 17.1289 10.7641 16.7981 10.2829 16.1364
        C9.80165 15.4704 9.55244 14.4778 9.53525 13.1587
        V11.5668
        C9.53525 10.1875 9.77372 9.16275 10.2507 8.49246
        C10.7276 7.82217 11.4752 7.48703 12.4936 7.48703
        C13.5033 7.48703 14.2466 7.81143 14.7236 8.46024
        C15.2005 9.10475 15.4454 10.1016 15.4583 11.4508
        V12.9976
        Z
        M14.266 11.367
        C14.266 10.3572 14.1242 9.62251 13.8406 9.16275
        C13.557 8.69871 13.108 8.46668 12.4936 8.46668
        C11.8834 8.46668 11.4387 8.69656 11.1594 9.15631
        C10.8801 9.61606 10.7362 10.3229 10.7276 11.2767
        V13.1845
        C10.7276 14.1985 10.8737 14.9483 11.1659 15.4338
        C11.4623 15.9151 11.9092 16.1557 12.5064 16.1557
        C13.0951 16.1557 13.5312 15.928 13.8148 15.4725
        C14.1027 15.0171 14.2531 14.2995 14.266 13.3198
        V11.367
        Z"
      />
      <line
        x1="8.4224"
        y1="16.3555"
        x2="17.2224"
        y2="7.55551"
        stroke={color}
        strokeWidth="2.19993"
      />
    </SvgIcon>
  );
};
